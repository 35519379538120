import { defineStore } from 'pinia';
import { useRuntimeConfig } from '#app';
import { useAuthStore } from "~/stores/AuthStore";

export const useSettingsStore = defineStore("settings", {
  state: () => ({
    profile: {},
    checkIsEqual: false,
    hasAttachments: false,
    groupApplicationRequest: {},
    desires: {},
    subscriptions: [],
    favorites: [],
    orders: [],
    marketerProducts: [],
    marketerSales: [],
    marketerPayments: [],
    loading: false,
    user: {
      name: "Ahmed",
      email: "ahmed@gmail.com",
    },
  }),

  actions: {
    async showProfile() {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}profile`,
          {
             method: "GET",
            headers: authStore.auth.headers,
          }
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401
          );
          return error.value.data;
        }
        this.profile = data.value ? data.value.data : {};
        // return data.value;
      } catch (e) {
        return e.response ? e.response.data : {};
      }
    },
    async fetchSubscriptions(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        this.loading = true
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}subscriptions`,
          {
            headers: authStore.auth.headers,
            params: {
              search_text: payload.search_text,
              per_page: payload ? payload.per_page : null,
              type: payload.type,
              page: payload.page,
            },
          }
        );
        this.loading = false

        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401
          );
          return error.value.data;
        }
        this.subscriptions = data.value ? data.value.data : {};
        return data.value;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchFavorites(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}favorites`,
          {
            headers: authStore.auth.headers,
            params: {
              per_page: payload ? payload.per_page : null,
              type: payload.type,
              page: payload.page,
            },
          }
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401
          );
          return error.value.data;
        }
        this.favorites = data.value ? data.value.data : {};
        return data.value;
      } catch (err) {
        console.error(err);
      }
    },
    async updateFavorites(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}favorites`,
          {
            headers: authStore.auth.headers,
            params: {
              per_page: payload ? payload.per_page : null,
              page: payload.page,
            },
          }
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401
          );
          return error.value.data;
        }
        return data.value;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchOrders(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}orders`,
          {
            headers: authStore.auth.headers,
            params: {
              per_page: payload?.per_page,
              type: payload?.type,
              page: payload?.page,
            },
          }
        );
        if (error.value) {
          checkStatusCode(
           error.value.data ? error.value.data.status_code : 401
          );
          return error.value.data;
        }
           this.orders = data.value ? data.value.data : {};
        return data.value;
      } catch (err) {
        console.error(err);
      }
    },
    async updateOrder(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}orders/${payload.id}`,
          {
            method: "POST",
            headers: authStore.auth.headers,
            body: payload.formData,
          }
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401
          );
          return error.value.data;
        }
        return data.value;
      } catch (err) {
        return err.response ? err.response.data : err;
      }
    },
    async getMarketerProducts(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      this.loading = true;
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}affiliated-items`,
          {
            headers: authStore.auth.headers,
            params: {
              search_text: payload.search_text,
              type: payload.type,
              page: payload.page,
            },
          }
        );
        this.loading = false;
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401
          );
          return error.value.data;
        }
        this.marketerProducts = data.value ? data.value.data : {};
        return data.value;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    async getMarketerSales(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}affiliated-sales`,
          {
            headers: authStore.auth.headers,
            params: {
              search_text: payload.search_text,
              per_page: payload ? payload.per_page : null,
              type: payload.type,
              page: payload.page,
            },
          }
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401
          );
          return error.value.data;
        }
        this.marketerSales = data.value ? data.value.data : {};
        this.orders = data.value ? data.value.data : {};
        return data.value;
      } catch (err) {
        console.error(err);
      }
    },
    async getMarketerPayments(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}affiliated-payments`,
          {
            headers: authStore.auth.headers,
            params: {
              search_text: payload.search_text,
              per_page: payload ? payload.per_page : null,
              type: payload.type,
              page: payload.page,
            },
          }
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401
          );
          return error.value.data;
        }
        this.marketerPayments = data.value ? data.value.data : {};
        return data.value;
      } catch (err) {
        console.error(err);
      }
    },
    async setGroupApplicationRequest(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      this.loading = true;
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}groups-application-requests`,
          {
            method: "POST",
            headers: authStore.auth.headers,
            body: payload,
          }
        );
        this.loading = false;
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401
          );
          return error.value.data;
        }
        this.groupApplicationRequest = data.value ? data.value.data : {};
        return data.value;
      } catch (err) {
        this.loading = false;
        return err.response ? err.response.data : err;
      }
    },
    async getGroupApplicationRequest(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      this.loading = true;
      try {
      const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}groups-application-requests/${payload}`,
          {
            headers: authStore.auth.headers,
          }
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401
          );
          return error.value.data;
        }
        this.groupApplicationRequest = data.value ? data.value.data : {};

        this.loading = false;
        return data.value;
      } catch (err) {
        return err.response ? err.response.data : err;
      }
    },
    async updateGroupApplicationRequest(payload) {
      this.loading = true;
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}groups-application-requests/${payload.id}`,
          {
            method: "POST",
            headers: authStore.auth.headers,
            body: payload.form_data,
          }
        );
        this.loading = false;
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401
          );
          return error.value.data;
        }
        this.groupApplicationRequest = data.value ? data.value.data : {};
        return data.value;
      } catch (err) {
        this.loading = false;
        return err.response ? err.response.data : err;
      }
    },

    async updateDesire(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}desires`,
          {
            method: "POST",
            headers: authStore.auth.headers,
            body: {
              id: payload.id,
              is_deleting: payload.is_deleting,
            },
          }
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401
          );
          return error.value.data;
        }
        return data.value;
      } catch (err) {
        return err.response ? err.response.data : err;
      }
    },
  },
});
