<template>
  <div>
    <Alert
      :alert-visible="showAlert"
      :alert-data="alertData"
      @close="showAlert = false"
    />
    <div class="cart-login cart-section">
      <!-- الإشعارات -->
      <Notifications class="notification-icon" v-if="isLogin" />
      <!-- السلة -->
      <v-badge
        v-if="cartList && cartList.length"
        :content="cartList && cartList.length > 0 ? cartList.length : '0'"
        overlap
        :offset-x="25"
        color="#98dede"
      >
        <v-btn class="cart-btn" :class="{ 'ml-6': !isLogin }" @click="goToCart">
          <img src="~/assets/images/header/cart.svg" alt="Cart Icon" />
          <span v-if="mdAndUp">السلة</span>
        </v-btn>
      </v-badge>
      <v-btn
        v-else
        class="cart-btn"
        :class="{ 'ml-6': !isLogin }"
        @click="goToCart"
      >
        <img src="~/assets/images/header/cart.svg" alt="Cart Icon" />
        <span v-if="mdAndUp">السلة</span>
      </v-btn>

      <v-btn
        v-if="isLogin && user"
        icon
        small
        to="/my-points"
        class="level-btn"
        variant="text"
      >
        <v-badge
          v-if="!hasCompletedProfile && cartList && cartList.length"
          color="red"
          dot
          top
          left
          offset-x="-10"
        >
          <img width="45" height="45" :src="user.level_icon" alt="Cart Icon" />
        </v-badge>

        <img
          v-else
          width="45"
          height="45"
          :src="user.level_icon"
          alt="Cart Icon"
        />
      </v-btn>
      <!-- تسجيل الدخول -->
      <span class="account-btns">
        <span v-if="!isLogin">
          <v-btn class="login" @click="showLogin = true"> دخول </v-btn>
          <v-btn class="signup" @click="signup"> تسجيل حساب </v-btn>
        </span>
        <!--  بيانات المستخدم -->
        <v-menu
          v-else-if="isLogin && user"
          rounded="lg"
          offset-y
          transition="slide-y-transition"
          min-width="216px"
          class="profile-menu"
        >
          <template v-slot:activator="{ props }">
            <v-btn class="user-btn" rounded v-bind="props">
              <v-avatar class="profile-img">
                <img :src="user.avatar" alt="img" />
              </v-avatar>
              <span>
                <span>{{
                  user.first_name
                    ? `${user.first_name}  ${user.last_name}`
                    : user.full_name
                }}</span>
                <img src="~/assets/images/header/mored.svg" class="icon" />
              </span>
            </v-btn>
          </template>
          <v-list class="profile-list">
            <div>
              <span class="user-data">
                <v-avatar class="profile-img">
                  <img :src="user.avatar" alt="profile img" />
                </v-avatar>
                <span>
                  <span>{{
                    user.first_name
                      ? `${user.first_name}  ${user.last_name}`
                      : user.full_name
                  }}</span>
                  <img src="/images/settings/more-up.svg" class="icon" />
                </span>
              </span>
              <ul>
                <li v-for="page in settings" :key="page.title">
                  <nuxt-link v-if="page.icon === 'profile'" :to="page.link">
                    <img
                      :src="`/images/settings/${page.icon}.svg`"
                      class="icon"
                    />
                    <span>{{ page.title }}</span>
                  </nuxt-link>

                  <nuxt-link
                    v-if="page.icon === 'group-application'"
                    :to="hasDesires"
                  >
                    <img
                      :src="`/images/settings/${page.icon}.svg`"
                      class="icon"
                    />
                    <span>{{ page.title }}</span>
                  </nuxt-link>

                  <span v-if="page.islogout" @click="logout" class="logout-btn">
                    <img
                      :src="`/images/settings/${page.icon}.svg`"
                      class="icon"
                    />
                    <span>{{ page.title }}</span>
                  </span>
                  <nuxt-link
                    v-else-if="
                      page.icon === 'marketing' &&
                      user.role === 'affiliate_marketer'
                    "
                    :to="page.link"
                  >
                    <img
                      :src="`/images/settings/${page.icon}.svg`"
                      class="icon"
                    />
                    <span>{{ page.title }}</span>
                  </nuxt-link>

                  <nuxt-link
                    v-if="
                      !page.islogout &&
                      page.icon !== 'profile' &&
                      page.icon !== 'marketing' &&
                      page.icon !== 'group-application'
                    "
                    :to="page.link"
                  >
                    <img
                      :src="`/images/settings/${page.icon}.svg`"
                      class="icon"
                    />
                    <span>{{ page.title }}</span>
                  </nuxt-link>
                </li>
              </ul>
            </div>
          </v-list>
        </v-menu>
      </span>
    </div>
    <AuthVisitorLoginModal
      :dialog-visible="showLogin"
      @closeModal="isLoginDialogClosed"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useDisplay } from "vuetify";
import { useRouter } from "vue-router";
import LoginModal from "~/components/auth/LoginModal.vue";
import Notifications from "~/components/header/Notifications.vue";
import Alert from "~/components/shared/Alert.vue";
import { useAuthStore } from "~/stores/AuthStore";
import { useSettingsStore } from "~/stores/SettingsStore";
import { useCartStore } from "~/stores/CartStore";
import { useHomeStore } from "~/stores/HomeStore";

const { mdAndUp } = useDisplay();
const settingsStore = useSettingsStore();
const homeStore = useHomeStore();
const cartStore = useCartStore();
const authStore = useAuthStore();

// Initialize store and router

const router = useRouter();
const { getValue } = useLocalStorage();

const showLogin = ref(false);
const alertData = ref({});
const showAlert = ref(false);
const hasDesires = ref("/group-application-request");

const settings = ref([
  {
    title: "إشتراكاتي",
    icon: "subscriptions",
    link: "/settings/subscriptions",
  },
  { title: "طلباتي", link: "/settings/orders", icon: "orders" },
  { title: "إدارة الحساب", link: "/settings/profile", icon: "profile" },
  { title: "التسويق", link: "/settings/marketing", icon: "marketing" },
  { title: "المفضلة", link: "/settings/favorites", icon: "favorites" },
  {
    title: "فئات الخصم",
    link: "/settings/groupApplication",
    icon: "group-application",
  },
  { title: "تسجيل الخروج", link: "", icon: "signout", islogout: true },
]);

const isLogin = computed(() => authStore.auth.isLoggedIn);
const cartList = computed(() => cartStore.cartList);
const user = computed(() => authStore.auth.user);
const hasCompletedProfile = computed(() => {
  return authStore.auth.user
    ? authStore.auth.user.has_completed_profile
    : false;
});

const profile = computed(() => authStore.auth.user);
const hasGroupApplicationRequest = computed(() => {
  if (profile.value) {
    return profile.value.gar_information;
  }
  return "unknown";
});

const fetchCartList = async () => {
  if ((authStore.auth.isLoggedIn && !cartList.value) || profile.value) {
    await cartStore.getCartDetails();
  }
  // if (isLogin.value) {
  //   settingsStore.showProfile();
  // }
};
watch(
  () => authStore.auth.isLoggedIn,
  async (val) => {
    if (val && !cartList.value) {
      await cartStore.getCartDetails();
    }
  },
);

watch(
  () => hasGroupApplicationRequest.value,
  (newVal) => {
    if (newVal !== "unknown") {
      hasDesires.value = "/settings/GroupApplication";
    } else hasDesires.value = "/group-application-request";
  },
  { deep: true },
);

onMounted(() => {
  if (authStore.auth.isLoggedIn) {
    cartStore.getCartDetails();
  } else {

    const coursesInLocalStorage = getValue("itemsInLocalStorage")
      ? JSON.parse(getValue("itemsInLocalStorage"))
      : [];
      
    if (
      !isLogin.value &&
      coursesInLocalStorage &&
      coursesInLocalStorage.length
    ) {
      setTimeout(() => {
        cartStore.getVisitorCartDetails(coursesInLocalStorage);
      }, 200);
    } else {
      cartStore.cartList = [];
    }
  }
});

const goToCart = () => {
  navigateTo("/cart");
};

const isLoginDialogClosed = (payload) => {
  if (payload.value) {
    showLogin.value = false;
    if (payload.showSignup) {
      signup();
    }
  }
};

const logout = async () => {
  const formData = new FormData();
  formData.append("device_id", getValue("deviceId"));
  const payload = {
    formData,
    device_id: getValue("deviceId"),
  };
  await authStore.logout(payload).then(async (resData) => {
    setAlertData(resData);
    if (resData.success) {
      await homeStore.fetchHome();
    }
    navigateTo("/");
  });
};

const setAlertData = (data) => {
  alertData.value = data;
  showAlert.value = true;
};

const signup = () => {
  navigateTo("/auth/signup");
};

setTimeout(() => {
  fetchCartList();
}, 1500);
</script>

<style lang="scss" scoped>
.profile-list {
  top: -45px;
  left: 12px;
}
.cart-section {
  justify-content: flex-end;
}

.cart-login {
  display: flex;
  align-items: center;
  button {
    border-radius: 21px;
    background-color: #87599d !important;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
  }
  .cart-btn {
    // padding: 22px 19.4px !important;
    margin-left: 10px;
    img {
      padding-left: 5px;
    }
  }
  @media (max-width: 947px) {
    .cart-btn {
      img {
        padding-left: 0px;
      }
    }
  }
  .login {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .signup {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 2px;
  }

  .user-btn {
    border-radius: 22px;
    background-color: #98dede !important;
    color: #468383 !important;
    padding: 0px 4px 0px 10px;
    min-width: 216px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: right;
    position: relative;
    &:before {
      background-color: transparent;
    }
    .profile-img img {
      width: 37px !important;
      height: 37px !important;
    }
    span {
      font-size: 15px;
      font-weight: bold;
      line-height: 1.87;
      text-align: right;
      color: #468383;
      margin-left: 12px;
    }
    .icon {
      position: absolute;
      left: 10px;
      top: 11px;
    }
  }
}
.v-list {
  width: 219px !important;
  box-shadow: none;
  border-radius: 22px !important;
  padding: 0 !important;

  z-index: 1001 !important;
  div {
    background-color: #98dede;
    padding: 10px 8.6px 13px;
    ul {
      padding: 10px 12px;
      list-style: none;
      background-color: #fff;
      border-radius: 18px;
      margin: 0;
      margin-top: 5px;
      font-weight: bold;
      font-size: 15px;
      font-weight: normal;
      li {
        margin: 20px 0;
        img {
          margin-left: 10px;
        }
        a,
        .logout-btn {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #000;
          cursor: pointer;
          &:hover,
          &.nuxt-link-active {
            color: #87599d;
          }
        }
      }
    }
  }
  .user-data {
    min-width: 199px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: right;
    position: relative;
    &:before {
      background-color: transparent;
    }
    .profile-img img {
      width: 37px !important;
      height: 37px !important;
    }
    span {
      font-size: 15px;
      font-weight: bold;
      line-height: 1.87;
      text-align: right;
      color: #468383;
      margin-left: 12px;
    }
    .icon {
      position: absolute;
      left: 7px;
      top: 10px;
    }
  }
}

@media (max-width: 768px) {
  .cart-section {
    .account-btns {
      display: none;
    }
  }
}
@media (max-width: 600px) and (min-width: 450px) {
  .cart-login {
    .cart-btn {
      // padding: 21px 17px !important;
      margin: 0 10px;
    }
  }
}
@media (max-width: 450px) {
  .cart-login {
    .cart-btn {
      // padding: 21px 14px !important;
      margin: 0 10px 0 0;
    }
  }
}
.cart-login .level-btn {
  background-color: transparent !important;
  margin: 0 5px 0 14px;
  padding: 0;
}
@media (max-width: 600px) {
  .cart-login .level-btn {
    margin: 0 0 0 10px;
  }
}
.notification-icon {
  position: relative !important;
  right: 10px !important;
}
</style>
