<template>
  <div class="notification">
    <!-- الإشعار -->
    <v-snackbar
      v-model="showNotifyAlert"
      :timeout="5000"
      bottom
      left
      class="notify-alert"
      transition="slide-x-reverse-transition"
    >
      <!-- <img
        :src="
          require(`~/assets/images/notifications/${getIconName(
            notifyAlertData
          )}.svg`)
        "
        alt="icon"
      > -->
      <img v-if="notifyAlertData.icon" :src="notifyAlertData.icon" alt="icon">
      <img v-else src="~/assets/images/notifications/accept-card.svg" alt="">
      <div>
        <div class="alert_title">
          <h3>{{ notifyAlertData.title }}</h3>
          <img src="~/assets/images/notifications/bell.svg" alt="icon">
        </div>
        <p>
          {{ notifyAlertData.body }}
        </p>
      </div>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  props: {
    notifyAlertVisible: {
      type: Boolean,
      default: false
    },
    notifyAlertData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    num: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showNotifyAlert: false
    };
  },
  watch: {
    notifyAlertVisible() {
      if (this.notifyAlertVisible === true) {
        this.showNotifyAlert = true;
      }
    },
    showNotifyAlert() {
      if (this.showNotifyAlert === false) {
        this.$emit("closeModal", { value: true });
      }
    }
  },
  methods: {
    getIconName(type) {
      if (type) {
        return "accept-card";
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
