import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@fortawesome/fontawesome-free/css/all.css'; // Ensure your project is capable of handling css
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import { en, ar } from 'vuetify/locale'
import { VDateInput } from 'vuetify/labs/VDateInput'

// eslint-disable-next-line no-undef
export default defineNuxtPlugin((app) => {
  const myCustomLightTheme = {
    dark: false,
    colors: {}
    
  };
  const vuetify = createVuetify({
    defaults: {
      VTextField: {
        variant:"solo"
      },
      VTextarea: {
        variant:"solo"
      },
      VSelect: {
        variant:"solo"
      },
      VAutocomplete: {
        variant:"solo"
      },
      global: {
        rtl: true,
      },
      // VBtn: {
      //   height:44,
      // },
    //   VCard: {
    //     rounded: 'lg',
    //   },
    //   VRadioGroup:{
    //     color:'#61aaa7'
    //   }
    },
    theme: {
      defaultTheme: 'light',
      themes: {
        myCustomLightTheme,
      },
    },
    ssr: true,
    components:{
      ...components,
      VDateInput
    },
    directives,
    locale: {
      locale: 'ar',
      fallback: 'ar',
      messages: { en, ar },
    },
    // rtl: {
    //   enabled: true,
    // },
    // display: {
    //   mobileBreakpoint: 'sm',
    //   thresholds: {
    //     xs: 0,
    //     sm: 400,
    //     md: 651,
    //     lg: 961,
    //     xl: 1600,
    //   },
    // },
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
  });

  app.vueApp.use(vuetify);
});
