// middleware/replaceCourseWithMartials.ts

import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app'

export default defineNuxtRouteMiddleware((to) => {

  // Check if "courses" exists in the path
  if (to.path.includes('courses')) {
    if (to.query) {
      const ref = useCookie('ref', {
        expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), // 7 days from now
      });
      ref.value = to.query.ref || '';

      const promo = useCookie('promo', {
        expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), // 7 days from now
      });
      promo.value = to.query.ref || '';
    }

    // Replace only "courses" with "martials" and keep the rest of the path, params, and query intact
    const newPath = to.path.replace('courses', 'materials');

    // Use navigateTo instead of redirect
    return navigateTo({
      path: newPath,
      query: to.query, // Ensure the query parameters are preserved
    });
  }
});
