import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45to_45materials_45global from "/app/middleware/redirectToMaterials.global.js";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45to_45materials_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  mainten: () => import("/app/middleware/mainten.js"),
  "redirect-if-unauthorized": () => import("/app/middleware/redirectIfUnauthorized.js"),
  soon: () => import("/app/middleware/soon.js")
}