import { defineNuxtPlugin } from "#app";
import { useAuthStore } from "@/stores/AuthStore";
import { useHomeStore } from "@/stores/HomeStore";
export default defineNuxtPlugin(async (nuxtApp) => {
  const authStore = useAuthStore(nuxtApp.$pinia);
  const homeStore = useHomeStore(nuxtApp.$pinia);
  const token = useCookie("token");
  if (!authStore.auth.isLoggedIn && token.value && token.value.length > 0) {
    authStore.setHeaders(token.value);

    if (!authStore.auth.user || !authStore.auth.user.name) {
      
      authStore.getProfile();
    }
  }


  if (process.client && !homeStore.loading && !homeStore.home) {
    homeStore.fetchHome();
  }
});
