<template>
  <v-card class="cart-alert change-email-card px-0 py-0">
    <div class="header-image" alt="change-email-header-image" />
    <v-card-title>
      <h3>تعديل البريد الإلكتروني</h3>
    </v-card-title>
    <v-btn icon dark @click="closeDialog" class="close-dialog-icon">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-form ref="formRef" class="modal-form px-10 py-2" lazy-validation>
      <v-text-field
        v-model="form.email"
        required
        class="input-email"
        placeholder="البريد الإلكتروني القديم"
        type="email"
        :rules="emailRules"
        validate-on-blur
      />
      <v-text-field
        v-model="form.new_email"
        required
        class="input-email"
        placeholder="البريد الإلكتروني الجديد"
        type="email"
        :rules="emailRules"
        validate-on-blur
      />
      <v-text-field
        v-model="form.email_confirmation"
        required
        class="input-email"
        placeholder="تأكيد البريد الإلكتروني الجديد"
        type="email"
        :rules="emailConfirmationRules"
        validate-on-blur
      />
      <v-text-field
        v-model="form.password"
        class="input-email"
        required
        placeholder="كلمة المرور المرتبطة بالحساب"
        type="password"
        :rules="passwordRules"
        validate-on-blur
      />

      <v-btn
        :loading="authStore.loading"
        class="login-btn"
        height="56"
        @click="changeEmail"
      >
        <span>تعديل</span>
      </v-btn>
    </v-form>
  </v-card>
</template>

<script setup>
import { ref, watch } from "vue";
import { useAuthStore } from "~/stores/AuthStore";

const authStore = useAuthStore();

const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["closeModal", "showAlert"]);

const formRef = ref(null);

const showloginDialog = ref(false);
const form = ref({
  email: "",
  new_email: "",
  email_confirmation: "",
  password: "",
});

const emailRules = [
  (v) => !!v || "البريد الإلكتروني مطلوب",
  (v) => checkEmail(v) === true || "البريد الإلكتروني غير صحيح",
];

const emailConfirmationRules = [
  (v) => form.value.new_email.length > 0 || "تأكيد البريد الإلكتروني مطلوب",
  () =>
    form.value.new_email.length === 0 ||
    checkEmail(form.value.new_email) === true ||
    "البريد الإلكتروني غير صحيح",
  (v) => v === form.value.new_email || "البريد الإلكتروني غير متطابق",
];

const passwordRules = [
  (v) => !!v || "كلمة المرور مطلوبة",
  (v) => v.length >= 2 || "طول كلمة المرور يجب أن لا يقل عن 2 أحرف",
];

watch(
  () => props.dialogVisible,
  (val) => {
    if (val) {
      showloginDialog.value = true;
    }
  },
);

watch(showloginDialog, (val) => {
  if (!val) {
    emit("closeModal", { value: true, showSignup: false });
    form.value = {
      email: "",
      new_email: "",
      email_confirmation: "",
      password: "",
    };
  }
});

const changeEmail = async () => {
  const valid =
    formRef.value && formRef.value.errors
      ? (await formRef.value.validate()).valid
      : true;

  if (valid) {
    const formData = new FormData();
    for (const key in form.value) {
      if (key !== "email_confirmation") {
        formData.append(key, form.value[key]);
      }
    }
    const resData = await authStore.changeEmail(formData);
    emit("showAlert", resData);
  }
};

const checkEmail = (value) => {
  if (value.includes("@")) {
    return /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value,
    );
  } else {
    return value;
  }
};

const closeDialog = () => {
  emit("closeModal");
};
</script>

<style lang="scss">
.header-image {
  width: 100%;
  height: 253px;
  padding: 5px;
  background-image: url("~/assets/images/auth/change-email-header-image.svg");
  background-position: center;
}
</style>
