<template>
  <div class="notification">
    <NotifyAlert
      :notify-alert-visible="showNotifyAlert1"
      :notify-alert-data="notifyAlertData"
      num="num1"
      class="num1"
      @closeModal="isNotifyAlertClosed"
    />
    <NotifyAlert
      :notify-alert-visible="showNotifyAlert2"
      :notify-alert-data="notifyAlertData"
      num="num2"
      class="num2"
      @closeModal="isNotifyAlertClosed"
    />
    <NotifyAlert
      :notify-alert-visible="showNotifyAlert3"
      :notify-alert-data="notifyAlertData"
      num="num3"
      class="num3"
      @closeModal="isNotifyAlertClosed"
    />
    <NotifyAlert
      :notify-alert-visible="showNotifyAlert4"
      :notify-alert-data="notifyAlertData"
      num="num4"
      class="num4"
      @closeModal="isNotifyAlertClosed"
    />
    <NotifyAlert
      :notify-alert-visible="showNotifyAlert5"
      :notify-alert-data="notifyAlertData"
      num="num5"
      class="num5"
      @closeModal="isNotifyAlertClosed"
    />
    <!-- قائمة الإشعارات -->

    <v-menu
      rounded="lg"
      offset-y
      persistent
      transition="slide-y-transition"
      min-width="216px"
      class="profile-menu"
    >
      <template v-slot:activator="{ props }">
        <v-btn size="small" icon v-bind="props" class="notification-btn">
          <v-badge
            :content="`${notificationsNum}`"
            overlap
            :offset-x="9"
            :offset-y="-7"
            color="#e5717b"
          >
            <img src="~/assets/images/notifications/notification.svg" />
          </v-badge>
        </v-btn>
      </template>

      <v-list class="box">
        <h3 class="title">
          الإشعارات
          <v-btn @click.stop="markAsRead('', true)" variant="text">
            قراءة الكل
          </v-btn>
        </h3>
        <span class="line" />
        <!-- List -->
        <div class="list">
          <div
            v-for="(item, i) in notificationsList"
            :key="i"
            @click="item.status == 'unread' ? markAsRead(item.id) : ''"
            class="notify"
            :class="{ unread: item.status == 'unread' }"
          >
            <span class="unread-circle" />
            <img v-if="item.isURL" :src="item.icon" alt="icon" />
            <img
              v-else
              :src="`/images/notifications-status/${item.icon}.svg`"
              alt="icon"
            />
            <div>
              <div class="n__title">
                <h3>{{ item.title }}</h3>
                <span>{{ item.created_at }}</span>
              </div>
              <p>
                {{ item.message }}
              </p>
            </div>
          </div>
        </div>
        <span class="line" />
        <span class="footer">
          <v-btn
            variant="text"
            @click.stop="fetchNotifications(response.current_page + 1)"
          >
            عرض الكل
          </v-btn>
        </span>
      </v-list>
    </v-menu>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

import NotifyAlert from "~/components/shared/NotifyAlert.vue";
import { useHomeStore } from "~/stores/HomeStore";
import { useAuthStore } from "~/stores/AuthStore";

const authStore = useAuthStore();
const homeStore = useHomeStore();

const response = ref({});
const notificationsNum = ref(0);
const notificationsList = ref([]);
const showNotifyAlert1 = ref(false);
const showNotifyAlert2 = ref(false);
const showNotifyAlert3 = ref(false);
const showNotifyAlert4 = ref(false);
const showNotifyAlert5 = ref(false);
const notifyAlertData = ref({});

const isLogin = computed(() => authStore.auth.isLoggedIn);

const fetchNotifications = async (currentPage = 1) => {
  const lastPage = response.value.last_page || 1;
  if (currentPage <= lastPage) {
    const params = { per_page: 6, page: currentPage };
    await homeStore.fetchNotifications(params);

    notificationsNum.value = homeStore.notifications.number_of_notifications;
    response.value = homeStore.notifications.notifications
      ? { ...homeStore.notifications.notifications }
      : {};
    const newData = response.value.data ? response.value.data : [];
    notificationsList.value.push(...newData);

    notificationsList.value.forEach((element) => {
      element.isURL = element.icon.startsWith("http");
    });
  }
};

const markAsRead = async (ids = "", readAll = false) => {
  const params = { ids, readAll };
  await homeStore.markAsRead(params).then(() => {
    const params = { per_page: notificationsList.value.length, page: 1 };
    homeStore.fetchNotifications(params).then(() => {
      notificationsNum.value = homeStore.notifications.number_of_notifications;
      notificationsList.value = homeStore.notifications.notifications.data;
    });
  });
};

const getIconName = (type) => {
  return type ? "accept-card" : "";
};

const startOnMessageListener = () => {
  // const { messaging } = store.$fire;
  // if (messaging !== undefined) {
  //   messaging.onMessage((payload) => {
  //     setNotifyAlertData({ ...payload.notification, ...payload.data });
  //     fetchNotifications();
  //   });
  // }
};

const setNotifyAlertData = (data = {}) => {
  notifyAlertData.value = data;
  if (!showNotifyAlert1.value) {
    showNotifyAlert1.value = true;
  } else if (!showNotifyAlert2.value) {
    showNotifyAlert2.value = true;
  } else if (!showNotifyAlert3.value) {
    showNotifyAlert3.value = true;
  } else if (!showNotifyAlert4.value) {
    showNotifyAlert4.value = true;
  } else if (!showNotifyAlert5.value) {
    showNotifyAlert5.value = true;
  } else {
    setNotifyAlertData(data);
  }
};

const isNotifyAlertClosed = (payload) => {
  if (payload.value) {
    showNotifyAlert1.value = false;
    showNotifyAlert2.value = false;
    showNotifyAlert3.value = false;
    showNotifyAlert4.value = false;
    showNotifyAlert5.value = false;
  }
};

setTimeout(() => {
  fetchNotifications();
  startOnMessageListener();
}, 1500);
</script>

<style lang="scss" scoped>
.v-list {
  width: 399px !important;
  background-color: #fff;
  border-radius: 8px;
}
.notification-btn {
  border-radius: 21px;
  background-color: #98dede !important;
  // padding: 24px 25px 20px !important;
  margin: 0px 0 0px 15px;
}
.box {
  padding-bottom: 0;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.81;
    color: #0c0b0b;
    padding: 5px 20px 5px 15px;
    button {
      font-size: 14px;
      font-weight: bold;
      color: #5b5377;
      padding: 0;
    }
  }
  .line {
    display: block;
    background-color: #ebeef0;
    // background-color: rgba(0, 0, 0, 0.16);
    height: 1px;
  }
  .footer {
    text-align: center;
    display: flex;
    justify-content: center;
    button {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.81;
      color: #5b5377;
    }
  }
  .list {
    height: 460px;
    overflow: auto;
    scrollbar-color: #5b5377 transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px transparent;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5b5377;
      outline: none;
      border-radius: 12px;
    }
    img {
      width: 20px;
    }
  }

  .notify {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0px 10px;
    width: 100%;
    img {
      margin-left: 10px;
    }
    & > div {
      width: 100% !important;
      padding: 7px 2px 5px 2px;
    }
    .n__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3 {
        font-size: 14px;
        font-weight: bold;
        color: #000;
      }
      span {
        color: #808184;
        font-size: 11px;
        font-weight: normal;
        flex: none;
      }
    }
    p {
      font-size: 12px;
      font-weight: bold;
      line-height: 2.33;
      color: #817d7d;
      margin: 0;
    }
  }
  .notify.unread {
    .unread-circle {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      position: absolute;
      top: 40%;
      margin-top: 2px;
      right: 2px;
      background-color: #f97065;
    }
  }
}
@media (max-width: 600px) {
  .v-list {
    top: 20px !important;
    width: 100% !important;
  }
  .notification-btn {
    // padding:  22px 21px 20px !important;
    margin: 0px 0 0px 5px;
  }
  .box {
    .list {
      height: 360px;
    }
  }
}
</style>
