import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import axios_sVCuMR6hEC from "/app/plugins/axios.js";
import redirectToMaterials_bYVbryHPsn from "/app/plugins/redirectToMaterials.js";
import sentry_PZVGm6LH3G from "/app/plugins/sentry.js";
import setHeaders_xwoqyNc43G from "/app/plugins/setHeaders.js";
import social_sharing_ntpaD0Rjds from "/app/plugins/social-sharing.ts";
import visible_3REAyvWE8C from "/app/plugins/visible.js";
import vue_chartkick_uiZJEIaPY8 from "/app/plugins/vue-chartkick.js";
import vue_step_progress_client_cnHbcJxBrS from "/app/plugins/vue-step-progress.client.js";
import vueCookies_W4JzBEX7eL from "/app/plugins/vueCookies.js";
import vuetify_8NhHJigKc1 from "/app/plugins/vuetify.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  axios_sVCuMR6hEC,
  redirectToMaterials_bYVbryHPsn,
  sentry_PZVGm6LH3G,
  setHeaders_xwoqyNc43G,
  social_sharing_ntpaD0Rjds,
  visible_3REAyvWE8C,
  vue_chartkick_uiZJEIaPY8,
  vue_step_progress_client_cnHbcJxBrS,
  vueCookies_W4JzBEX7eL,
  vuetify_8NhHJigKc1
]