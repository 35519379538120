<template>
  <div>
    <v-dialog
      v-model="showAlert"
      width="max-content"
      :persistent="persistent"
      :retain-focus="false"
    >
      <v-card class="cart-alert">
        <v-card-actions>
          <img :src="`/images/settings/${iconName}.svg`" alt="Alert Icon" />
        </v-card-actions>
        <div>
          <p>{{ msg }}</p>
        </div>
        <div>
          <v-btn
            v-if="showLoginBtn"
            :loading="loading"
            height="50"
            class="login-btn"
            @click="login"
          >
            <span>تسجيل الدخول</span>
          </v-btn>
          <v-btn
            v-if="showRetryBtn"
            :loading="loading"
            height="50"
            class="login-btn"
            @click="retryActivation"
          >
            <span>أعد المحاولة</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useNuxtApp } from "#app";
import { useAuthStore } from "~/stores/AuthStore";

const authStore = useAuthStore();

const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false,
  },
  dialogData: {
    type: Object,
    default: () => ({}),
  },
  persistent: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["closeModal"]);
const route = useRoute();

const showAlert = ref(false);
const showLoginBtn = ref(false);
const showRetryBtn = ref(false);
const loading = ref(false);
const activationType = ref("");
const msg = ref("");
const iconName = ref("sent");
const query = ref(route.query);

const alertMessage = () => {
  activationType.value = props.dialogData.type;
  msg.value = props.dialogData.msg;
  if (activationType.value === "emailActivationSuccessed") {
    iconName.value = "sent";
    showLoginBtn.value = true;
    showRetryBtn.value = false;
  } else if (activationType.value === "emailActivationFailed") {
    iconName.value = "failed";
    if (msg.value === "بريدك الإلكتروني مفعل مسبقاً") {
      showRetryBtn.value = false;
      showLoginBtn.value = true;
    } else {
      showRetryBtn.value = true;
      showLoginBtn.value = false;
    }
  }
};

const login = () => {
  showAlert.value = false;
  emit("closeModal", { value: true, showLogin: true });
};

const retryActivation = async () => {
  if (query.value.token && query.value.email) {
    loading.value = true;
    const payload = {
      token: query.value.token,
      email: query.value.email,
    };
    const resData = await authStore.activateAccount(payload);

    loading.value = false;
    activationType.value = resData.success
      ? "emailActivationSuccessed"
      : "emailActivationFailed";
    msg.value = resData.message;
    alertMessage();
  }
};

watch(
  () => props.dialogVisible,
  (newValue) => {
    alertMessage();
    showAlert.value = newValue;
  },
  { deep: true },
);
</script>

<style scoped>
/* Add your styles here */
</style>

<style lang="scss" scoped>
.cart-alert {
  min-width: 248px;
  height: 261px;
  padding: 20px 25px;
  border-radius: 23px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: none;
  margin: 0 auto;
  p {
    font-size: 17px;
    margin-top: 5px;
    font-weight: bold;
    line-height: 1.53;
    text-align: center;
  }
}
.login-btn {
  margin: 10px 0px;
  border-radius: 20px;
  border: solid 1px #707070;
  background-color: #464688 !important;
  width: 100%;
  span {
    font-size: 13px;
    font-weight: bold;
    color: #fff;
  }
}
</style>
