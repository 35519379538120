import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    const config = useRuntimeConfig();
    const router = useRouter();
    const {
      public: { sentry },
    } = useRuntimeConfig();

    if (!sentry.dsn) {
      return;
    }
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: sentry.dsn,
      environment: sentry.environment,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],

      // Configure this whole part as you need it!

      tracesSampleRate: 0.1, // Change in prod

      beforeSend(event, hint) {
        // Check if it is an exception, and if so, log it.
        if (event.exception) {
          // eslint-disable-next-line no-console
          console.error(
            `[Exeption handled by Sentry]: (${hint.originalException})`,
            { event, hint },
          );
        }
        // Continue sending to Sentry
        return event;
      },
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        "https://testing-customer.calwe.com/",
      ],

      replaysSessionSampleRate: 0.1, // Change in prod
      replaysOnErrorSampleRate: 0.1, // Change in prod if necessary
    });

    Sentry.setContext("version details", {
      git_commit_id: config.public.sentry_git_commit_id,
    });

    return {
      provide: {
        sentrySetContext: Sentry.setContext,
        sentrySetUser: Sentry.setUser,
        sentrySetTag: Sentry.setTag,
        sentryAddBreadcrumb: Sentry.addBreadcrumb,
        sentryCaptureException: Sentry.captureException,
      },
    };
  }
});
