import { defineNuxtPlugin } from "#app";
import { useHomeStore } from "@/stores/HomeStore";

export default defineNuxtPlugin(async (nuxtApp) => {
  // if (process.client) {
    // setTimeout(() => {
      // const homeStore = useHomeStore(nuxtApp.$pinia);
      // const route = useRoute();
      // const router = useRouter()
      // if (route.path.includes("courses")) {
      //   if (route.query) {
      //     // homeStore.setRouteQuery(route.query);
      //   }
      //   const newPath = route.path.replace("courses", "materials");
      //   return navigateTo({ path: newPath, query: route.query }, 301);
      // }
    // }, 100);
  // }
});
