export default function useLocalStorage() {
  function getValue(key) {
    if (process.client) {
      if (localStorage.getItem(key)) return localStorage.getItem(key);
    }
  }

  function setValue(key, value) {
    if (process.client && value) {
      localStorage.setItem(key, value);
    }
  }
  function removeValue(key) {
    if (process.client) {
      localStorage.removeItem(key);
    }
  }
  return {
    getValue,
    setValue,
    removeValue,
  };
}
