<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="393.513"
      viewBox="0 0 393.513 362.702"
      class="BG_IMG-of-page"
    >
      <path
        id="Path_13518"
        data-name="Path 13518"
        d="M3.266-.192,393,0l-.291,352.089c-10.438,5.067-56.68,10.421-68.831,10.421l-324.392-.2S-.047,42.043-.348,37.648c.111-2.266.541-10.407,1.4-18.965C1.548,11.139,2.852,2.187,3.266-.192Z"
        transform="translate(0.513 0.192)"
        :fill="color"
      />
    </svg>

    <!-- <img class="BG_IMG-of-page" src="~/assets/images/shapes/FAQBG.svg" alt="BG img"> -->
  </div>
</template>

<script>
export default {
  name: "BackgroundImage",
  props: {
    color: { type: String, default: "#ffd895" }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.BG_IMG-of-page {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: -10;
}
@media (max-width: 1600px) {
  .home__section {
    padding: 0px 6%;
  }
  .page__section {
    padding: 0px 6%;
  }
  .BG_IMG-of-page {
    width: 333px;
  }
}
@media (max-width: 1264px) {
  .BG_IMG-of-page {
    width: 280px;
  }
}
@media (max-width: 960px) {
  .BG_IMG-of-page {
    width: 164px;
    right: -6px;
  }
}

@media (max-width: 768px) {
}
</style>
